import { isSupported } from '@archipro-website/localisation';
import type { ProfessionalDetailsInitialFragment } from 'generated/graphql';
import type { Breadcrumb } from '@modules/professional/type/details-root.types';
import type { DropdownItem } from '~/modules/enquiry/component/enquiry-form/EnquiryForm';
import type { Branch } from '~/modules/enquiry/util/extract-branch-from-network.server';
import { useMatches } from '@remix-run/react';
import { useMemo } from 'react';

import {
    PROFESSIONAL_DETAILS_BASE_ROUTE_ID,
    PREVIEW_BASE_ROUTE_ID,
} from '@modules/business-manager/config/constants';
import type { PrimaryCTAResult } from '@modules/professional/util/professional-cta-helper';
import type { ProfessionalBannersData } from '@modules/professional/page/OverviewPage';
import type { ProfessionalDetailRootData } from '../page/ProfessionalPage';

export type ProfessionalInitialResult = ReturnType<
    typeof mapProfessionalInitialRouteData
>;

export const useProfessionalInitialRouteData = () => {
    const matchingRoutes = useMatches();

    const route = useMemo(() => {
        return matchingRoutes.find((route) => {
            return [
                PROFESSIONAL_DETAILS_BASE_ROUTE_ID,
                PREVIEW_BASE_ROUTE_ID,
            ].includes(route.id);
        });
    }, [matchingRoutes]);

    const {
        initial,
        banners,
        professionalBreadcrumb,
        branchOptions,
        primaryCTA,
    } = route?.data as ProfessionalDetailRootData;

    return mapProfessionalInitialRouteData(
        initial,
        banners,
        professionalBreadcrumb,
        branchOptions,
        primaryCTA
    );
};

/*
![TECH DEBT] (exposed graphql) Loaders should not be returning raw graphql types/fragments.
 */
export const mapProfessionalInitialRouteData = <
    T extends ProfessionalDetailsInitialFragment,
>(
    obj: T,
    banners?: ProfessionalBannersData,
    breadcrumbs?: Breadcrumb[],
    branchOptions?: DropdownItem<Branch>[],
    primaryCTA?: PrimaryCTAResult
) => ({
    ID: obj.ID,
    Link: obj.Link,
    CompanyLogo: obj.CompanyLogo ?? '',
    LogoBackgroundColor: obj.LogoBackgroundColor ?? 'white',
    BaseUrl: `/professional/${obj.URLSegment}`,
    Title: obj.Title,
    Categories: obj.Categories,
    Website: obj.Website ?? undefined,
    CustomEnquiryMessage: obj.CustomEnquiryMessage ?? undefined,
    TeamCalendarURLSegment: obj.TeamCalendarURLSegment ?? undefined,
    EnquiryResponseRate: obj.EnquiryResponseRate,
    PageType: obj.PageType,
    Country: isSupported(obj.Country) ? obj.Country : 'NZ',
    LibraryLocations: obj.LibraryLocations.filter((l) => !l.LocationHidden),
    URLSegment: obj.URLSegment,
    HasProducts: obj.HasProducts,
    HasProjects: obj.HasProjects,
    HasProjectsUsingThisProfessional: obj.HasProjectsUsingThisProfessional,
    HasVideos: obj.HasVideos,
    HasArticles: obj.HasArticles,
    HasPartners: obj.HasPartners,
    ProductsAllowed: obj.ProductsAllowed,
    Banners: banners ?? [],
    Breadcrumbs: breadcrumbs ?? [],
    branchOptions: branchOptions ?? [],
    ArchiproEditorialAccount: obj.ArchiproEditorialAccount,
    primaryCTA,
    AvailableForWork: obj.AvailableForWork,
    Content: obj.Content ?? '',
    AssociatedPages: obj.AssociatedPages,
});
